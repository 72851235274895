import * as React from "react";
import { Link, graphql, PageProps } from "gatsby";
import Layout from "../layouts";
import * as styles from "./blog-post.module.scss";
import "./_markdown-style.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { motion } from "framer-motion";
import useAnimationContext from "../contexts/animation-context";
import { navigate } from "@reach/router";
import {
  getExitVariant,
  AnimationDirection,
  getInitialVariant,
} from "../utilities/animation";
import Header from "../components/header";
import { OutboundLink } from "gatsby-plugin-google-gtag";

interface BlogPostNavProps {
  data: {
    fields: {
      slug: string;
    };
    frontmatter: {
      featuredImage: any;
      title: string;
    };
  };
  direction: "next" | "previous";
}
const BlogPostNav = ({
  data: {
    fields: { slug },
    frontmatter: { featuredImage, title },
  },
  direction,
}: BlogPostNavProps): JSX.Element => {
  deckDeckGoHighlightElement();

  const heroImage = getImage(featuredImage);
  const { blogPostPageAnimationControls } = useAnimationContext();

  const { LTR, RTL } = AnimationDirection;
  const animationDirection = direction === "next" ? RTL : LTR;

  return (
    <Link
      className={styles.navLink}
      to={`/blogs${slug}`}
      rel={direction}
      state={{ initialAnimateDir: animationDirection }}
      onClick={async (event): Promise<void> => {
        event.preventDefault();
        const exitVariant = getExitVariant(animationDirection);
        if (exitVariant) {
          await blogPostPageAnimationControls.start(exitVariant);
        }
        navigate(`/blogs${slug}`, {
          state: { initialAnimateDir: animationDirection },
        });
      }}
    >
      <div
        className={cx(styles.blogItem, {
          [styles.next]: direction === "next",
          [styles.previous]: direction === "previous",
        })}
      >
        <div className={styles.navBackgroundImageContainer}>
          {heroImage && (
            <GatsbyImage
              className={styles.navBackgroundImage}
              image={heroImage}
              alt="hero image"
            />
          )}
        </div>
        <div className={styles.navContent}>
          <div className={styles.title}>{title}</div>
          <div className={styles.arrow}>
            <FontAwesomeIcon
              fixedWidth
              icon={direction === "next" ? faChevronRight : faChevronLeft}
              size="lg"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

const BlogPostTemplate = ({
  data: {
    markdownRemark,
    site: {
      siteMetadata: {
        title: siteMetadataTitle,
        socials: { linkedin: linkedInHandle, email },
      },
    },
    previous,
    next,
  },
  location,
}: PageProps<any>) => {
  const post = markdownRemark;
  const {
    frontmatter: { description, date, title: postTitle, featuredImage },
    fields: { slug },
    excerpt,
    html,
  } = post;
  const siteTitle = siteMetadataTitle || `Title`;
  const heroImage = getImage(featuredImage);

  const postDescription = description || excerpt;
  const [month, day, year] = date.split(" ");

  const { state, pathname } = location;
  let initialAnimateDir;
  if ((state as any)?.initialAnimateDir) {
    initialAnimateDir = (state as any).initialAnimateDir;
  }

  const { variants, blogPostPageAnimationControls } = useAnimationContext();

  React.useEffect((): void => {
    blogPostPageAnimationControls.start("visible");
  }, []);

  const { LTR } = AnimationDirection;
  const animationDirection = LTR;

  // For simplicity, we define "finished reading" as when the user reaches the
  // bottom of the page.
  const [hasFinishedReading, sethasFinishedReading] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  // Add scroll event listener to know when a user reaches the end of an
  // article.  We don't want to show the "Continue reading" article on the home
  // page if they've already finished reading it.
  React.useEffect((): (() => void) => {
    const onScroll = (event: any) => {
      if (!containerRef.current) {
        return;
      }
      const { scrollHeight } = containerRef.current;
      const {
        scrollY,
        screen: { height: screenHeight },
      } = event.currentTarget;
      const estimatedFooterHeight = 200;
      if (scrollY + screenHeight + estimatedFooterHeight >= scrollHeight) {
        sethasFinishedReading(true);
        localStorage.removeItem(`@scroll/${slug}`);
      } else {
        localStorage.setItem(`@scroll/${slug}`, scrollY);
      }
    };

    if (!hasFinishedReading) {
      window.addEventListener("scroll", onScroll);
    }

    return () => window.removeEventListener("scroll", onScroll);
  }, [hasFinishedReading]);

  // Manual scroll restoration
  React.useEffect((): void => {
    const lastScrollPosition = localStorage.getItem(`@scroll/${slug}`);
    if (
      (state as any)?.shouldRestoreScroll &&
      lastScrollPosition &&
      !isNaN(Number(lastScrollPosition))
    ) {
      window.scrollTo(0, Number(lastScrollPosition));
    }
  }, []);

  return (
    <motion.div
      ref={containerRef}
      key={pathname}
      initial={getInitialVariant(initialAnimateDir)}
      animate={blogPostPageAnimationControls}
      variants={variants}
    >
      <div className={styles.fixedHeader}>
        <Header
          title={siteTitle}
          location={location}
          blogPostInfo={!hasFinishedReading ? post : undefined}
          themeSpec="light"
          visualSpec="reduced-height"
        />
      </div>
      <Layout
        location={location}
        title={siteTitle}
        seoInfo={{
          title: post.frontmatter.title,
          description: postDescription,
        }}
        visualSpec="h-centered"
        headerThemeSpec="light"
        blogPostInfo={!hasFinishedReading ? post : undefined}
        className={styles.layoutContainer}
      >
        <div className={styles.intro}>
          <div className={styles.backgroundImageContainer}>
            {heroImage && (
              <GatsbyImage
                className={styles.backgroundImage}
                image={heroImage}
                alt="hero image"
              />
            )}
          </div>
          <div className={styles.introContent}>
            <Link
              className={styles.backLink}
              to="/blogs"
              state={{ initialAnimateDir: animationDirection }}
              onClick={async (event): Promise<void> => {
                event.preventDefault();
                const exitVariant = getExitVariant(animationDirection);
                if (exitVariant) {
                  await blogPostPageAnimationControls.start(exitVariant);
                }
                navigate("/blogs", {
                  state: { initialAnimateDir: animationDirection },
                });
              }}
            >
              <FontAwesomeIcon fixedWidth icon={faArrowLeft} size="sm" />
              <span className={styles.backLinkContent}>All Blog Posts</span>
            </Link>
            <h1 className={styles.title}>{postTitle}</h1>
            <hr className={styles.divider} />
            <p className={styles.summary}>{postDescription}</p>
          </div>
          <footer className={styles.meta}>
            <div className={styles.date}>
              <span className={styles.month}>{month}</span>
              <span className={styles.day}>{day}</span>
              <span className={styles.year}>{year}</span>
            </div>
          </footer>
        </div>
        <div className={styles.content}>
          <article
            className="blog-post"
            itemScope
            itemType="http://schema.org/Article"
          >
            <section
              dangerouslySetInnerHTML={{ __html: html }}
              itemProp="articleBody"
            />
          </article>
          <footer className={styles.footer}>
            <p className={styles.contactMessage}>
              For further questions or inquiries, feel free to message me on
              LinkedIn or send me an email!
            </p>
            <div className={styles.socials}>
              <OutboundLink
                className={styles.socialLink}
                href={`https://linkedin.com/in/${linkedInHandle}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  fixedWidth
                  className={styles.socialIcon}
                  icon={faLinkedin}
                  size="lg"
                />{" "}
                linkedin.com/in/
                {linkedInHandle}
              </OutboundLink>
              <OutboundLink
                className={styles.socialLink}
                href={`mailto:${email}&subject=Hi!"`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  fixedWidth
                  className={styles.socialIcon}
                  icon={faEnvelope}
                  size="lg"
                />{" "}
                {email}
              </OutboundLink>
            </div>
            {(previous || next) && (
              <nav className={styles.blogNav}>
                <div className={cx(styles.navItem, styles.previous)}>
                  {previous && (
                    <BlogPostNav data={previous} direction="previous" />
                  )}
                </div>
                <div className={cx(styles.navItem, styles.next)}>
                  {next && <BlogPostNav data={next} direction="next" />}
                </div>
              </nav>
            )}
          </footer>
        </div>
      </Layout>
    </motion.div>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        socials {
          linkedin
          github
          email
        }
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMM DD YYYY")
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
  }
`;
